.search-params
    transition: $transition
    margin-top: 0.5rem

    @include respond-to(tablet)
        width: 95%
        flex-direction: column-reverse
        align-items: center
        gap: 0
        margin-bottom: 1rem

    .search-params-content
        @include flex(row, flex-start, center, 0.5rem)

    .search-params-dates, .search-params-limit
        @include flex(row, space-between, center, 0rem)

    .search-params-date-range
        @include flex(row, center, center, 0rem)

    .search-params-sep
        color: $text-color-faint
        
    .search-params-title, .search-params-hidden
        @include text(smaller, 400, $text-color-faint, left, $font-family, 1rem)
        padding: 0.1rem 
        text-align: right
        border: 1px solid transparent
        border-radius: $border-radius-small
        @include non-selectable-text()
        transition: $transition

        @include respond-to(tablet)
            font-size: $font-size-smaller
            padding: 0.1rem 0

    .search-params-hidden
        cursor: pointer
        border: 1px solid transparent
        padding: 0.2rem 0.5rem

        &:hover
            color: $secondary700
            border-color: $text-color-faint
            background-color: rgba($secondary, 0.05)

    .search-params-hidden.close
        border-color: $text-color-faint


    input[type="number"]
        width: 4.5rem
        padding: 0.05rem 0.5rem
        border-radius: $border-radius-small
        border: 1px solid $grey900
        background-color: white
        outline: none
        @include text(smaller, 400, $grey500, center, $font-family-mono)
        -moz-appearance: textfield
        font-size: $font-size-smaller

 

    .input-year-start
        border-radius: $border-radius-small 0 0 $border-radius-small !important
        border-right: none !important

    .input-year-end
        border-radius: 0 $border-radius-small $border-radius-small 0 !important
