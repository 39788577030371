.payment-container 
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding: 0rem 2rem
    font-family: $font-family
    width: 100%

    h2 
        font-size: $font-size-large
        font-weight: bold
        margin-bottom: 0.5rem
        color: $text-color

    .payment-form 
        max-width: 400px
        width: 100%
        padding: 1rem 2rem

        h3
            font-size: $font-size
            font-weight: bold
            margin-bottom: 0.5rem
            color: $secondary600

    .form-group 
        margin-bottom: 0.5rem
        font-size: 1em

    .form-group label 
        display: block
        font-size: 0.875rem
        font-weight: 500
        color: $text-color
        margin-bottom: 0.5rem

    .card-element-container 
        border: $border-default
        border-radius: $border-radius-small
        padding: 0.5rem
        background-color: $background-color-light

    button 
        width: 100%
        padding: 0.5rem 1rem
        background-color: $secondary
        color: white
        border: none
        border-radius: $border-radius-small
        font-size: $font-size
        font-weight: 500
        cursor: pointer
        transition: $transition

        &:hover 
            background-color: $primary900

        &:disabled 
            opacity: 0.5
            cursor: not-allowed

        &.processing 
            opacity: 0.75

    .payment-status 
        margin-top: 0.5rem
        text-align: center
        font-weight: 700
        border-radius: $border-radius-small

    .payment-status.success 
        color: $success
        background-color: rgba($success, .1)

    .payment-status.error 
        color: $error
        background-color: rgba($error, .1)

    @include respond-to(mobile)
        padding: 0

        .payment-form
            padding: 0 !important

.demo-completed-title
    @include respond-to(mobile)
        font-size: 1.75rem !important
        margin-bottom: 1rem !important

            