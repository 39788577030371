.highlight
    background: rgba($secondary500, 0.3)
    padding: 0.1rem 0.5rem !important
    border-radius: 0.2rem
    font-weight: 800 !important

        
.demo-landing
    .navbar_brand
        font-size: 3rem !important

    .navbar_logo
        width: 3rem !important


    @include respond-to(mobile)
        .refy_navbar_container
            .refy_navbar
                border: none !important

    
.ta-login-manager
    @include flex(row-reverse, space-between, flex-start, 5rem)
    width: 90%
    max-width: 1200px
    margin: 0 auto
    min-height: 100vh

    @include respond-to(mobile)
        flex-direction: column !important
        gap: 2rem
        padding-top: 4rem
        padding-bottom: 4rem


    .ta-login-manager-description
        width: 50%
        padding-top: 8rem !important

        @include respond-to(mobile)
            padding-top: 0rem !important
            width: 100%

        .ta-login-manager-description-inner
            @include flex(column, flex-start, flex-start, 0rem)

        .ta-login-title
            @include text(2.1em, 700, $accent, center, $font-family-bold)

        .ta-login-subtitle
            @include text(1.2em, 400, $secondary600, center, $font-family)
            margin-bottom: 1.5rem !important

        .ta-login-features
            list-style-type: none
            padding-left: 0
            width: 90%
            margin-left: 3rem !important

            @include respond-to(mobile)
                margin-left: 0 !important
                width: 100%
                text-align: center

            li
                @include text(1em, 400, $text-color, left, $font-family)
                margin-bottom: 0.5rem
                font-family: 'Montserrat', sans-serif !important

                &:before
                    content: "•"
                    color: $secondary
                    font-weight: bold
                    font-size: 1.2em
                    line-height: 1.2em
                    display: inline-block
                    width: 1em

                @include respond-to(mobile)
                    text-align: center

                    &:before
                        display: none


        .ta-login-new-info
            @include text(0.9em, 400, $secondary600, left, $font-family)

        .ta-login-cta
            margin: 1rem auto !important
            font-size: 1.2em !important
            width: fit-content !important
            margin-top: 1rem

        .ta-login-trial
            @include text(0.8em, 400, $secondary600, center, $font-family)
            margin-top: 0.5rem
            width: 80%
            margin: 0 auto



    .ta-login-manager-illustration
        perspective: 1000px
        width: 50%
        height: 90%

        .paper-flip-container
            width: 80%
            margin: 0 auto
            height: 80vh
            position: relative
            transform-style: preserve-3d

        .paper-flip-front,
        .paper-flip-back
            position: absolute
            width: 100%
            height: 80vh !important
            max-height: 600px
            backface-visibility: hidden
            display: flex
            align-items: center
            justify-content: center
            background-color: white
            border-radius: 10px
            box-shadow: $box-shadow-blue-large
            border: $border-default
            z-index: 1
            position: relative
            display: block


        .paper-flip-title
            @include text(1.8em, 700, $secondary, center, $font-family)
            position: absolute
            top: 1.5rem
            padding: 0 1.5rem
            background: rgba(white, 0.5)
            backdrop-filter: blur(2px)

            strong
                color: $accent
                font-weight: 800
                font-style: underline

    .cl-rootBox
        margin: 0 auto !important

    .cl-formButtonPrimary
        background: $blue-bright !important
        border: none !important
        box-shadow: none !important
 



.ta-login-cta
    margin-top: 1rem

.ta-login-manager
    .ta-login-manager-illustration
        @include respond-to(mobile)
            width: 100%
            height: auto
            margin-bottom: 2rem

        .mobile-cards-container
            @include flex(row, center, center, 1rem)
            height: 60vh !important

        .mobile-card
            width: 100%
            height: 60vh !important
            transform: none !important

        .paper-flip-container
            @include respond-to(mobile)
                width: 100%
                height: 60vh !important
                margin-bottom: 1rem

        .paper-flip-front,
        .paper-flip-back
            @include respond-to(mobile)
                height: 60vh !important

        .paper-flip-title
            @include respond-to(mobile)
                font-size: 1.4em
                padding: 0 1rem
