/* -------------------------------------------------------------------------- */
/*                               GENERAL LAYOUT                               */
/* -------------------------------------------------------------------------- */
.papers-display
  margin-top: 2rem
  max-width: 1200px
  width: 80%
  margin: 0 auto

.papers-display-desktop
    @include flex(row, space-between, stretch, 0rem)
    flex-grow: 1
    height: auto

.papers-display-left
    flex: 0 0 55% 

.papers-display-right
    flex: 0 0 calc(45% - 2rem)

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENTS                              */
/* -------------------------------------------------------------------------- */

.papers-list-container.hide-controls
    .papers-list
        border-radius: $border-radius !important

.papers-list, .paper-details
    overflow-y: auto
    @include scrollbar-hidden
    background-color: white
    max-height: 1000px
    border-radius: $border-radius 
    border: $border-default
    height: 65vh
    box-shadow: $box-shadow-light

    @include respond-to(desktop)
        height: 80vh
        margin-bottom: 1rem

    @include respond-to(mobile)
        height: 1200px


/* ------------------------------- papers list ------------------------------ */


.papers-list
    @include flex(column, flex-start, stretch)
    @include scrollbar-hidden
    padding: 1rem
    border-radius: 0 0 $border-radius $border-radius

    @include respond-to(tablet)
        max-height: 500px

    .papers-list-element
        @include flex(row, space-between, flex-start, 1rem)
        padding: 0.5rem 1rem
        border-bottom: $border-light
        cursor: pointer
        transition: background-color 0.3s ease
        transition: transform 0.2s ease

        @include respond-to(tablet)
            flex-direction: column-reverse

        &:hover
            background-color: $background-color-light
            transform: translateY(-2px)

            .paper-title
                color: $secondary 

        &.busy
            @include fadeCycle(0.8, 0.1, 2.0s)
            opacity: 0.6
            pointer-events: none

        .paper-details-left
            @include flex(column, flex-start, flex-start)

            @include respond-to(tablet)
                width: 100%
                flex-direction: row
                gap: 0
                align-items: center
                justify-content: center


            .label
                @include text(smaller, 400, $text-color-dark, right)
                margin-right: 0.5rem
                color: $text-color

            strong
                width: 2.8rem


            .paper-score, .paper-year, .paper-citations
                @include flex(row, flex-start, center)

                .label
                    @include text(smaller, 400, $text-color-dark, right)
                    margin-right: 0.5rem
                    color: $text-color

                strong
                    --highlight-color: #{$accent}
                    --default-score-color: #{$secondary}
                    
                    color: var(--default-score-color)
                    width: 2.8rem
                    @include text(smaller, 600, $text-color-dark, right)
                    margin-right: 0.4rem

                    .faint
                        @include text(smallest, 400, $text-color, left)

            @include respond-to(tablet)
                flex-wrap: wrap
                gap: 0.0rem


        .paper-details-right
            @include flex(column, flex-start, flex-start)
            flex-grow: 1

            .paper-title-level
                margin-bottom: 0.3rem

                .paper-title
                    @include text(small, 500, black, left, $font-family, 1rem)
                    display: -webkit-box
                    -webkit-line-clamp: 2
                    -webkit-box-orient: vertical
                    overflow: hidden

                    @include respond-to(tablet)
                        font-size: $font-size-small
                        -webkit-line-clamp: 4

            .paper-journal
                font-style: italic
                display: -webkit-box
                -webkit-line-clamp: 1
                -webkit-box-orient: vertical
                overflow: hidden
                text-align: left
                flex-grow: 1
                padding: 0 0.5rem
                font-size: $font-size-smaller
                color: $text-color

                @include respond-to(tablet)
                    font-size: $font-size-smaller

    // placeholders
    .placeholder
        cursor: default
        @include non-selectable-text
        color: transparent !important

        strong
            color: transparent !important

    .paper-details-right.placeholder
        flex-grow: 1
        width: 100%
        color: transparent !important

    .paper-details-left.placeholder
        color: transparent !important
        background-color: rgba($grey900, .8)
        border-radius: $border-radius

        .label
            color: transparent !important

    .paper-authors.placeholder
        @include placeholder-text(1.2rem, $grey900, 100%)
        margin-top: 0.25rem
        margin-left: 1rem


    .paper-title-level.placeholder
        @include placeholder-text(1.2rem, $grey800, 90%)
        margin-bottom: 0.25rem
        .paper-title
            color: transparent !important

    .paper-journal.placeholder
        @include placeholder-text(1.2rem, rgba($grey900, .7), 20%)
        margin-left: 0rem
        width: 80%
        flex-grow: 1

.papers-list-element.placeholder
    cursor: default
    @include non-selectable-text

    &:hover
        background-color: white !important

.papers-list-element.selected 
    background-color: rgba($secondary100, .4)


/* ------------------------------ single paper ------------------------------ */

.pd-no-paper
    @include text(largest, 700, $text-color-dark, center, $font-family, 1.4rem)   

.paper-details
    padding: 1rem 2rem
    height: calc(65vh + 2.5rem)

    @include respond-to(tablet)
        margin-top: 0rem
        padding: 1rem

    &.hide-controls
        height: 65vh !important

.pd-title
    @include text(large, 700, $text-color-dark, center, $font-family, 1.4rem)
    margin-bottom: 1rem

    a
        color: $text-color-dark

        &:hover
            color: $secondary

.pd-level
    @include level-flex(space-between, space-between, 0.5rem)

    @include respond-to(tablet)
        width: 100%
        flex-direction: column
        // align at center
        align-items: center

        .pd-journal
            width: 100%
            text-align: center


.pd-level-left
    @include respond-to(tablet)
        @include flex(row, space-between, center, 0.5rem)

.pd-authors
    @include text(small, 400, $text-color, center, $font-family, 1.2rem)
    margin-bottom: 0.5rem

.level
    margin-bottom: 0
    gap: 2rem

.pd-year, .pd-citations, .pd-journal, .pd-doi
    font-size: $font-size-small
    color: $text-color
    margin-bottom: 0rem

    strong
        margin-right: 0.2rem

    @include respond-to(tablet)
        font-size: $font-size-smaller
        line-height: 1rem

.pd-journal
    max-width: 80%
    font-style: italic

.pd-citations
    flex-grow: 1

.pd-section
    margin-top: 1.5rem

    .pd-section-title
        font-size: $font-size-small
        font-weight: 600
        color: $secondary

    .pd-section-text
        padding: 0 0.5rem
        text-justify: inter-word
        @include text(small, 400, $text-color-dark, justify, $font-family, 1.2rem)

        @include respond-to(tablet)
            padding: 0 0rem

.pd-title.placeholder
    @include placeholder-text(2rem, $grey800, 100%)

.pd-authors.placeholder
    @include placeholder-text(1.2rem, $grey900, 100%)

.pd-year.placeholder,.pd-citations.placeholder
    @include placeholder-text(1.2rem, $grey950, 100%)
    margin-bottom: 0.25rem
    color: transparent !important
    strong
        color: transparent !important

.pd-journal.placeholder
    @include placeholder-text(1.2rem, $grey950, 100%)
    margin-bottom: 0.25rem
    color: transparent !important

.pd-section-title.placeholder
    @include placeholder-text(1.2rem, rgba($secondary100, .8), 20%)
    margin-bottom: 0.25rem
    color: transparent !important

.pd-section-text.placeholder
    @include placeholder-text(1.2rem, $grey950, 100%)
    margin-bottom: 0.25rem
    color: transparent !important
    height: 4rem


/* -------------------------------------------------------------------------- */
/*                                 SMART SORT                                 */
/* -------------------------------------------------------------------------- */

.sort-controls-wrapper
    // --background-color: #{$secondary100}
    @include flex(column, flex-start, stretch, 0.5rem)
    width: 100%
    padding: 0.5rem
    border-radius: $border-radius $border-radius 0 0 
    transition: background-color 0.3s ease
    background-color: $grey950
    border: 1px solid $grey800
    border-bottom: none
    

.sort-buttons-container
    @include flex(row, space-between, center, .2rem)
    width: 100%
    
    @include non-selectable-text

    @include respond-to(mobile)
        margin: 0.5rem 0
        flex-direction: column
        gap: 0.5rem
        height: auto

.smart-sort-button
    font-size: $font-size-smaller !important
    padding: 0.25rem 0.5rem !important
    width: fit-content !important
    display: flex
    justify-content: space-between
    align-items: center
    background-color: white
    color: $accent
    border: 1px solid $accent
    border-radius: 2.5rem !important

    &:hover
        box-shadow: 0 0 0.5rem rgba($accent, 0.2)

    .smart-sort-chevron
        margin-left: 0.5rem
        transition: transform 0.3s ease


.smart-sort-textarea-container
    @include flex(row, flex-start, flex-start)

    textarea
        width: 95%
        height: 6rem
        border-radius: $border-radius 0 0 $border-radius
        border: none
        padding: 0.5rem 1rem
        border: 1px solid $primary200
        resize: none
        border-right: none
        outline: none
        transition: $transition
        background-color: rgba(white, 1)
        color: $text-color

        &:focus
            border-color: $accent

    button
        height: 6rem
        width: 5%
        border-radius: 0 $border-radius $border-radius 0
        border: 1px solid $accent
        background-color: $accent 
        color: white
        transition: $transition

        &:focus
            border-color: $accent

    button.disabled
        background-color: $grey950
        border: 1px solid $grey800
        color: $grey500
        cursor: default

    @include respond-to(mobile)
        textarea
            width: 90%

        button
            width: 10%
.sort-buttons 
    display: flex
    
    button 
        padding: 0.15rem 0.5rem
        font-size: $font-size-small
        background-color: white
        border: 1px solid $grey800
        cursor: pointer
        transition: $transition
        color: $text-color
        background-color: $background-color-light   

        
        &.busy
            opacity: 0.6
            pointer-events: none
            animation: pulse 1s infinite

        @include respond-to(tablet)
            font-size: $font-size-smallest

        &:hover
            background-color: $background-color-light
            color: $text-color-dark

        &.active
            background-color: $secondary
            color: white
            border-color: $secondary

        &.disabled
            background-color: $background-color-light
            color: $grey800
            cursor: default
            border-color: $border-color-dark

    .left-button
        border-radius: $border-radius 0 0 $border-radius
        border-right: none !important


    .middle-button
        border-radius: 0
        border-right: none !important

    .right-button
        border-radius: 0 $border-radius $border-radius 0

.papers-count 
    font-size: $font-size-small
    color: $text-color
    text-align: right
    padding-right: 1rem

    @include respond-to(tablet)
        font-size: $font-size-smaller

.papers-list-element.placeholder
    cursor: default
    @include non-selectable-text

    &:hover
        background-color: white !important

.papers-list-element.selected 
    background-color: rgba($secondary100, .4)

/* ---------------------------- Voting Buttons ---------------------------- */
.pd-vote-buttons
    @include flex(row, flex-end, center, 0rem)
    margin-top: 0.2rem
    padding-right: 0.5rem

.pd-vote-button
    @include flex(row, center, center)
    background: transparent
    border: 1px solid $grey700
    color: $grey400
    padding: 0.2rem .6rem
    border-radius: $border-radius
    cursor: pointer
    transition: $transition

    &:hover
        border-color: $grey400

    &.active
        background-color: $grey400
        color: white
        border-color: $grey400
    svg
        font-size: 0.9rem

    &.left
        border-radius: $border-radius 0 0 $border-radius
        border-right: none !important
        // padding-bottom: 0.3rem

    &.right
        border-radius: 0 $border-radius $border-radius 0
        // padding-top: 0.3rem
