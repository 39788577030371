/* --------------------------------- colors --------------------------------- */
$primary900: #29323d
$primary800: #3b4754
$primary700: #4a5a6a
$primary600: #5a6e81
$primary500: #677d92
$primary400: #7e90a3
$primary300: #95a4b4
$primary200: #b3beca
$primary100: #d0d8e0
$primary50: #eceff4
$primary:#4a5a6a



$accent: #e01b50

$secondary900: #203758
$secondary800: #33557f
$secondary700: #3d73ac
$secondary600: #447fb7
$secondary500: #6daee3
$secondary400: #86bfe8
$secondary300: #9fcfed
$secondary200: #b9d0de
$secondary100: #cad9e1
$secondary50: #d1d4d7
$secondary: #3182ce

$blue: #007bff
$blue_bright: #1971dc


$grey: rgb(.5, .5, .5)
$grey50: rgb(0.05 * 255, 0.05 * 255, 0.05 * 255)
$grey100: rgb(0.1 * 255, 0.1 * 255, 0.1 * 255)
$grey200: rgb(0.2 * 255, 0.2 * 255, 0.2 * 255)
$grey300: rgb(0.3 * 255, 0.3 * 255, 0.3 * 255)
$grey400: rgb(0.4 * 255, 0.4 * 255, 0.4 * 255)
$grey500: rgb(0.5 * 255, 0.5 * 255, 0.5 * 255)
$grey600: rgb(0.6 * 255, 0.6 * 255, 0.6 * 255)
$grey700: rgb(0.7 * 255, 0.7 * 255, 0.7 * 255)
$grey800: rgb(0.8 * 255, 0.8 * 255, 0.8 * 255)
$grey900: rgb(0.9 * 255, 0.9 * 255, 0.9 * 255)
$grey950: rgb(0.95 * 255, 0.95 * 255, 0.95 * 255)
$grey975: rgb(0.975 * 255, 0.975 * 255, 0.975 * 255)

/* -------------------------------- variables ------------------------------- */

$ai-gradient: linear-gradient(135deg, #667eea 0%, #764ba2 100%)

$border-radius-small: 0.25rem
$border-radius: 0.5rem
$border-radius-large: 1rem
$border-radius-larger: 2.5rem
$border-color: $grey800
$border-color-dark: $grey700
$border-light: 1px solid $grey900
$border-default: 1px solid $border-color

$box-shadow: 1px 2px 5px rgba(black, 0.1)
$box-shadow-dark: 0 0 0.5rem rgba(0, 0, 0, 0.2)
$box-shadow-light: 1px 2px 5px rgba(0, 0, 0, 0.07)
$box-shadow-blue-large: 0 0 1rem rgba($secondary700, 0.25)

    

$transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)

$text-color: #2d3748
$text-color-light: #4a5568
$text-color-dark: #1a202c
$text-accent: #2b6cb0
$text-color-faint: color.scale($secondary600, $saturation: -40%, $lightness: 20%)

$error: #f04d17
$success: #059669

$background-page: #f7fafc
$background-color: #ffffff
$background-color-light: #f7fafc
$background-color-dark: #2d3748    

// $font-family: 'Nunito Sans', sans-serif
$font-family-bold: "DM Serif Display", serif
// $font-family-mono: 'Space Mono', monospace
$font-family: 'Montserrat', sans-serif
$font-family-mono: 'Fira Code', monospace

$font-size: 1rem
$font-size-largest: 2rem
$font-size-larger: 1.5rem
$font-size-large: 1.2rem
$font-size-small: 0.9rem
$font-size-smaller: 0.8rem
$font-size-smallest: 0.7rem


$navbar-width: 200px
$navbar-width-small: 50px