@use 'sass:color'

@import theme
@import mixins
@import papers
@import tryapp
@import payment_form
@import search_parameters
@import survey
@import onboarding
@import demo_landing

@keyframes fadeIn 
  from 
    opacity: 0

  to 
    opacity: 1

@keyframes spin 
    15% 
        transform: rotate(0deg)
    30% 
        transform: rotate(180deg)
    70% 
        transform: rotate(180deg)
    85%
        transform: rotate(0deg)

@keyframes borderAlpha
    0%
        border-color: rgba($secondary, 0)
    50%
        border-color: rgba($secondary, 1)
    100%
        border-color: rgba($secondary, 0)

@keyframes pulse
  0% 
    transform: scale(1)
  50% 
    transform: scale(1.05)
  100% 
    transform: scale(1)



// App container
.app-container
  @include flex(row)
  height: 100vh
  width: 100vw

.views-container
    height: 100vh
    overflow-y: auto
    @include transition(margin-left)
    width: calc(100vw - #{$navbar-width})  // Adjust width to account for navbar
    margin-left: $navbar-width

    @media (max-width: 768px)
        width: 100vw
        margin-left: 0

body
    background: linear-gradient(to bottom, $background-page, darken($background-page, 8%)) !important
    min-height: 100vh
    background-attachment: fixed
    
    // background-blend-mode: overlay
    // &::before
    //     content: ''
    //     position: absolute
    //     top: 0
    //     left: 0
    //     right: 0
    //     bottom: 0
    //     background-image: url('../../public/topography.svg')
    //     opacity: 0.02  // Adjust this value to make the background image more or less transparent
    //     z-index: 0
    //     pointer-events: none


/* -------------------------------------------------------------------------- */
/*                                   NAVBAR                                   */
/* -------------------------------------------------------------------------- */
// Navbar
.nav-bar
    @include flex(column, flex-start, flex-start)
    background-color: color.adjust($secondary800, $saturation: -10%)
    color: white
    width: $navbar-width
    height: 100vh
    padding: 0
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1)
    @include transition(transform)
    position: fixed  // Fix the navbar position
    left: 0
    top: 0
    z-index: 1000

    @media (max-width: 768px)
        width: 100vw
        transform: translateX(-100%)
        padding-top: 50px

        &.open
            transform: translateX(0)

            .nav-toggle
                width: 100%
                background-color: $secondary
.nav-toggle
    display: none
    position: fixed
    z-index: 1001
    background-color: $secondary
    color: white
    border: none
    border-radius: 0 0 1rem 0
    font-size: 1.5rem
    padding: 0.7rem
    cursor: pointer

    @media (max-width: 768px)
        display: block

    .nav-toggle-text
        @include flex(row, center, center)
        gap: 0.5rem

.nav_user, .nav_settings
  padding: 1rem 2rem
  width: 100%

.nav_menu
  @include flex(column, flex-start, stretch, 0.5rem)
  flex-grow: 1
  width: 100%
  padding: 2rem 1rem
  border-top: 1px solid rgba(white, 0.1)
  border-bottom: 1px solid rgba(white, 0.1)

.nav_menu_item
  padding: 0.75rem 1.5rem
  border-radius: 0.5rem
  cursor: pointer
  @include transition

  &:hover
    background-color: rgba(white, 0.1)

  &.active
    background-color: $secondary
    color: white

.nav_user
  @include flex(column, flex-start, flex-start, 0.2rem)
  @include non-selectable-text()

.nav_user_name
  @include text(larger, 600, white, left, $font-family-bold)


.nav_user_subscription
  font-size: 0.9rem
  opacity: 0.7

.nav_settings
  @include flex(column, flex-end, flex-start, 0.2rem)

.nav_settings_item
  cursor: pointer
  opacity: 0.7
  @include transition

  &:hover
    opacity: 1


.modal_overlay
    position: fixed
    // width: calc(100vw - #{$navbar-width})
    // margin-left: $navbar-width
    width: 100vw
    height: 100vh
    background: rgba($grey900, .7)
    z-index: 100
    backdrop-filter: blur(5px)
    top: 0
    left: 0

    @include respond-to(tablet)
        width: 100vw
        margin-left: 0

    .modal_box
        height: fit-content
        width: 80%
        max-height: 90vh
        max-width: 1200px
        overflow-y: auto
        margin: 2rem auto
        border: $border-default
        padding: 2rem 4rem
        border-radius: $border-radius
        background: white
        box-shadow: $box-shadow
        position: relative

        @include respond-to(tablet)
            width: 90%
            padding: 4rem 2rem


    .modal_title
        margin-bottom: 1rem
        @include text(largest, 700, $secondary, left, $font-family-bold)

    .modal_content
        font-size: 0.9em
        color: $grey
        margin-bottom: 1rem
        text-align: justify

        p, ul
            margin-bottom: 1.5rem !important

    button
        margin-top: 2rem
        
    strong
        color: $secondary200
        font-weight: 700

    a
        color: $text-color-dark
        font-weight: 700

    b
        color: $secondary

    .modal_close_button
        position: absolute
        top: 0rem
        right: 2rem
        font-size: 1.1rem
        width: fit-content
        color: $secondary
        cursor: pointer
        z-index: 101
        padding: 0.5rem
        line-height: 1

// Global styles
input[type='file']
    display: none


body
  font-family: $font-family
  background-color: $background-color


.refy_box
    border: $border-default
    border-radius: $border-radius-large !important
    padding: 0 2rem 1rem 2rem
    // margin-bottom: 0rem
    // margin-top: .5rem
    position: relative
    background: white

    &:hover 
        box-shadow: $box-shadow
        border: $border-default
        border-color: $secondary600


.inline_image
    height: 2rem
    display: inline-block



/* -------------------------------------------------------------------------- */
/*                                   BUTTONS                                  */
/* -------------------------------------------------------------------------- */

.button-primary
    background-color: $blue_bright
    color: white
    border: 1px solid $blue_bright

    &:hover
        box-shadow: 2px 2px 0.5rem rgba($blue_bright, 0.4)
        // scale: 1.01

.button-accent
    background-color: white
    color: $accent
    border: 1px solid $accent

    &:hover
        box-shadow: 2px 2px 0.5rem rgba($accent, 0.4)
        background-color: $accent
        color: white
        // scale: 1.01

.button-secondary
    background-color: white
    color: $blue_bright
    border: 1px solid $blue_bright

    &:hover
        box-shadow: 2px 2px 0.5rem rgba($secondary, 0.2)
        .button-text
            text-decoration: underline  

        
.text-button
    background-color: transparent
    color: $blue_bright
    border: none
    font-weight: 400 !important

    &:hover
        text-decoration: underline


.refy_button
    font-weight: 600
    border-radius: $border-radius 
    width: 12rem
    text-align: center
    padding: 0.5rem 1rem
    cursor: pointer
    transition: all 0.1s ease
    overflow: hidden
    height: fit-content
    @include flex(row, center, center, 0.1rem)

    &.disabled
        // greyed out red-ish color 
        background-color: $grey950
        border: 1px solid $grey800
        color: $grey500
        cursor: default

    &.disabled:hover
        box-shadow: none
        scale: 1 !important
        border: 1px solid $grey600 

    &.busy
        background-color: $grey950
        border: 1px solid $grey800
        color: $text-color
        cursor: default

    &.busy:hover
        box-shadow: none
        scale: 1 !important
        border: 1px solid $grey800 !important

    label
        margin-bottom: 0 !important


.button-wide
    width: 24rem

    @include respond-to(tablet)
        margin: 0 auto

    

.button-hide
    width: 0rem !important
    padding: 0rem !important
    overflow: hidden    
    border: none !important


/* -------------------------------------------------------------------------- */
/*                               BIBTEXT LOADER                               */
/* -------------------------------------------------------------------------- */

.upload-buttons-container
    @include flex(column, space-between, flex-start, 0rem)
    width: 25rem
    border-radius: $border-radius
    border: 2px solid transparent
    position: relative 
    margin-left: 2rem
    
.upload-buttons-container-inner
    @include flex(row, space-between, center, 0rem)
    height: 2rem
    // margin: 0 auto

.loaded-file-name-container
    margin-top: 0.5rem
    @include flex(row, flex-start, flex-start, 0.2rem)
    padding: 0
    height: fit-content !important
    border-radius: $border-radius 
    z-index: 1
    font-size: 0.8rem

.loaded-file-name-label
    padding-top: 0.05rem
    @include text(smaller,600, $secondary600,)
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.loaded-file-name
    @include text(smaller, 400, $text-color-faint, left, $font-family-mono)
    padding: 0
    border: none
    height: fit-content
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.loaded-file-size
    color: $text-color-faint
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.loaded-file-name-container.has-file
    opacity: 1

.upload-buttons.has-file
    padding: 0.5rem 0 !important

    @include respond-to(tablet)
        padding-bottom: 0 !important