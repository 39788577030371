
.survey, .popup-survey
    width: 100%

    h2
        @include text(larger, 600, $secondary, center, $font-family)
        margin-bottom: 1rem

    textarea
        resize: none
        border: $border-default
        border-radius: $border-radius
        padding: 0.5rem
        margin-bottom: 0.5rem
        width: 100%

    .rating-container
        margin-bottom: 1rem

        label
            display: block
            margin-bottom: 0.5rem

    .rating-buttons
        @include flex(row, center, space-between, 0rem)
        width: 80%
        margin-bottom: 0.5rem
        margin: 1rem auto 0 auto

    .rating-button
        padding: 0.5rem
        border: 1px solid $secondary
        color: $secondary
        background-color: white
        cursor: pointer
        transition: all 0.2s ease
        border-left: none
        font-size: $font-size-large

        // add radius to first and last button
        &:first-child
            border-radius: 0.5rem 0 0 0.5rem
            border-left: 1px solid $secondary

        &:last-child
            border-radius: 0 0.5rem 0.5rem 0

        &:hover
            background-color: $secondary100

        &.selected
            background-color: $secondary
            color: white

    .rating-labels
        display: flex
        justify-content: space-between
        margin-top: 0.25rem
        width: 60%
        margin: 0 auto
        color: $grey600 !important

    .survey-buttons
        @include flex(row, flex-end, center, 1rem)
        margin-top: 1rem

    .thank-you-message
        @include text(large, 600, $secondary, center, $font-family)
        margin-top: 1rem


.popup-survey
    position: fixed
    bottom: 2%
    right: 2%
    width: 40vw 
    max-width: 1200px
    background-color: white
    padding: 2rem
    border-radius: $border-radius-large
    border: 1px solid $secondary
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.25)

    .survey-buttons
        width: 80%
        margin: 0 auto
        justify-content: space-between
