
.try-app
    width: 100%
    min-height: 100vh
    overflow-y: auto
    padding: 0rem 0 4rem 0

    @include respond-to(tablet)
        padding: 1rem 0 8rem 0

    @include respond-to(mobile)
        padding: 2.5rem 0 6rem 0


.ta-search-description-container
    @include flex(column, flex-start, flex-start, 0rem)
    max-width: 1200px
    margin: 0 auto
    width: 80%
    display: block
    height: fit-content
    padding: 0
    margin-bottom: 1rem

    @include respond-to(tablet)
        width: 90%

    @include respond-to(mobile)
        width: 95%


.try-app-search
    @include flex(row, flex-start, flex-start, 0rem)

    @include respond-to(tablet)
        width: 90%
        flex-direction: column
        align-items: stretch
        gap: 0
        padding-top: 1rem

    @include respond-to(mobile)
        width: 100%
        padding: 0

    .try-app-search-left
        width: 80%
        padding: 0rem 

    .try-app-search-right
        width: 100%


    @include respond-to(tablet)
        .try-app-search-left,
        .try-app-search-right
            width: 100%
            margin-left: 0
            border-radius: $border-radius-large
            margin-bottom: 1rem


.try-app-search-header
    @include flex(row, flex-start, center, 10rem)
    padding-left: 2rem
    padding-bottom: 0.5rem !important

    .try-app-search-header-icons
        @include flex(row, space-between, center, 0.5rem)
        color: color.adjust($secondary900, $saturation: -20%, $lightness: 60%)
        padding-top: 0.5rem

        i
            cursor: pointer

            &:hover
                color: $secondary900

.search-instructions
    @include text(smaller, 400, $text-color, left, $font-family-mono, 1.2em)
    border-radius: $border-radius
    border: $border-light
    background-color: rgba(white, 0.8)
    

.ta-start-button
    width: 2rem !important // Initial width to fit just the icon
    border-left: none !important
    border-radius: 0 $border-radius $border-radius 0 !important
    padding: 0.5rem !important
    display: flex
    justify-content: flex-start
    align-items: center
    transition: $transition
    overflow: hidden

    i
        margin: 0 !important
        line-height: 1.5rem
        height: 1.5rem
        transition: margin 0.3s ease

    svg
        font-size: 1.2rem
        margin: 0
        transition: margin 0.3s ease


.bib-upload-button
    border-radius:  $border-radius 0 0 $border-radius !important 

    @include respond-to(tablet)
        width: 70vw !important

.try-app-results
    width: 100%
    max-width: 1600px
    margin: 0 auto

    @include respond-to(tablet)
        padding: 0 0.5rem
        width: 95%

    @include respond-to(mobile)
        padding: 0 0.25rem

     
.title-in-the-background
    @include non-selectable-text()
    font-size: 2.2rem
    font-weight: 700
    color: color.adjust($secondary900, $saturation: -20%, $lightness: 50%)
    padding-left: 2rem
    opacity: 0.6

    strong
        color: color.adjust($secondary900, $saturation: -20%, $lightness: 20%)

    @include respond-to(mobile)
        text-align: center
        padding: 0
        font-size: 1.5rem
    


/* -------------------------------------------------------------------------- */
/*                                 OLD NAVBAR                                 */
/* -------------------------------------------------------------------------- */
.refy_navbar_container
    width: 100%
    padding: 1rem 0 1.5rem 0

    .cl-userButtonTriggerm .cl-button, .cl-open, .cl-internal-c4jh7f
        border: none !important
        width: 3rem


.refy_navbar
    height: 3rem
    width: 80%
    margin: 0 auto
    display: flex
    position: static !important

    @include respond-to(mobile)
        flex-direction: column !important
        border-bottom: 2px solid $secondary200

        .navbar_links
            margin: 0 !important
            display: flex   
            flex-direction: row
            justify-content: center !important



    .navbar_logo
        width: 1.5rem
        height: auto
        opacity: 0.8
        margin-right: 0.5rem
    
    .navbar_brand
        font-size: 2.2em !important
        color: white
        font-weight: bold
        text-decoration: none
        line-height: 3rem
        color: $secondary700 !important
        font-family: "DM Serif Display", serif
        font-size: 3em

    .navbar_links
        margin-left: auto
        margin-right: 1rem
        line-height: 3rem
        color: rgba($secondary, .5) !important
        display: flex !important

        .navbar_link
            color: white
            text-decoration: none
            margin: 0 1rem
            color: $grey400 !important
            font-weight: 500
            display: inline-block
            cursor: pointer

            &:hover
                color: $secondary !important

        .nav-user-info
            @include flex(column, center, flex-end, 0rem)
            @include text(smaller, 400, $text-color, right, $font-family-mono)
            margin-right: 0 !important
            margin-left: 2rem !important

            &:hover
                color: $text-color !important
                cursor: default !important

            @include respond-to(mobile)
                display: none !important

        .user-button-container
            position: relative
            display: flex

            @include respond-to(mobile)
                padding-top: 0.5rem

        .search-count-bubble
            --size: 25px

            display: none
            position: absolute 
            top: calc(0.5rem - 8px )
            right: -8px
            background-color: white
            color: $secondary
            border: 1px solid $secondary
            border-radius: 50%
            width: var(--size)
            height: var(--size)
            font-size: 0.6em
            line-height: var(--size)
            text-align: center
            font-weight: bold

            @include respond-to(mobile)
                display: block !important




/* -------------------------------------------------------------------------- */
/*                               demo completed                               */
/* -------------------------------------------------------------------------- */

.demo-completed-title
    width: fit-content
    margin: 0rem auto 3rem auto
    text-align: center
    @include text(larger, 700, $secondary700, center, $font-family-bold)
    font-size: 3.5rem
    @include non-selectable-text()
    position: relative
    padding: 0.5rem 1rem 0 1rem
    border-bottom: 2px solid rgba($secondary, 0.5)

    @include respond-to(mobile)
        font-size: 2.5rem
        padding: 0.3rem 0.5rem


.demo-over
    width: 90%
    max-width: 1200px
    margin: 2rem auto
    @include flex(row, space-between, stretch, 2rem)
    padding: 0
    @include non-selectable-text()

    @include respond-to(tablet)
        flex-direction: column
        width: 95%

    .demo-over-content
        width: 48%
        height: 100%
        margin: 0
        padding: 2rem
        @include flex(column, flex-start, flex-start, .5rem)
        text-align: left
        background: white
        border-radius: $border-radius
        box-shadow: $box-shadow-blue-large
        transition: $transition

        @include respond-to(tablet)
            width: 90%
            margin: 0 auto
            margin-bottom: 2rem

    h1
        @include text(larger, 700, $secondary700, left, $font-family)
        margin-bottom: 0.25rem

    h2
        @include text(large, 600, $secondary600, left, $font-family)
        // margin-bottom: 0.5rem

    h3
        @include text(large, 600, color.adjust($secondary600, $saturation: -10%, $alpha: -0.4), left, $font-family)
        // margin-bottom: 0.5rem

    p
        @include text(small, 400, $text-color, left, $font-family)
        // line-height: 1.6

    .highlight
        background: linear-gradient(to bottom, transparent 50%, rgba($secondary300, 0.3) 50%)
        padding: 0 0.2rem

    .offer
        background-color: rgba($secondary100, 0.2)
        border-left: 4px solid $secondary
        padding: 1rem
        border-radius: 0 $border-radius $border-radius 0
        @include text(small, 500, $secondary700, left, $font-family)

    .payment-container
        width: 100%
        margin-top: 1rem

/* -------------------------------------------------------------------------- */
/*                               STATUS DISPLAY                               */
/* -------------------------------------------------------------------------- */

.status-display
    @include flex(column, flex-end, flex-start, 0rem)
    flex-direction: column-reverse
    max-height: 100px
    overflow-y: auto
    flex-direction: column-reverse
    padding: 1.5rem 1rem 0.5rem 0rem

    @include respond-to(mobile)
        align-items: center
        justify-content: center
        padding: 0

    .status-message
        @include flex(row, flex-start, center, 0.5rem)
        @include text(small, 400, rgba($secondary700, 0.8), left, $font-family-mono)

        &.subitem
            margin-left: 2rem
