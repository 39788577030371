@charset "UTF-8";
/* --------------------------------- colors --------------------------------- */
/* -------------------------------- variables ------------------------------- */
.non-selectable-text {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

/* -------------------------------------------------------------------------- */
/*                               GENERAL LAYOUT                               */
/* -------------------------------------------------------------------------- */
.papers-display {
  margin-top: 2rem;
  max-width: 1200px;
  width: 80%;
  margin: 0 auto;
}

.papers-display-desktop {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 0rem;
  flex-grow: 1;
  height: auto;
}

.papers-display-left {
  flex: 0 0 55%;
}

.papers-display-right {
  flex: 0 0 calc(45% - 2rem);
}

/* -------------------------------------------------------------------------- */
/*                               MAIN COMPONENTS                              */
/* -------------------------------------------------------------------------- */
.papers-list-container.hide-controls .papers-list {
  border-radius: 0.5rem !important;
}

.papers-list, .paper-details {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: white;
  max-height: 1000px;
  border-radius: 0.5rem;
  border: 1px solid #cccccc;
  height: 65vh;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.07);
}
.papers-list::-webkit-scrollbar, .paper-details::-webkit-scrollbar {
  display: none;
}
@media (max-width: 1200px) {
  .papers-list, .paper-details {
    height: 80vh;
    margin-bottom: 1rem;
  }
}
@media (max-width: 576px) {
  .papers-list, .paper-details {
    height: 1200px;
  }
}

/* ------------------------------- papers list ------------------------------ */
.papers-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 1rem;
  border-radius: 0 0 0.5rem 0.5rem;
}
.papers-list::-webkit-scrollbar {
  display: none;
}
@media (max-width: 992px) {
  .papers-list {
    max-height: 500px;
  }
}
.papers-list .papers-list-element {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
  transition: background-color 0.3s ease;
  transition: transform 0.2s ease;
}
@media (max-width: 992px) {
  .papers-list .papers-list-element {
    flex-direction: column-reverse;
  }
}
.papers-list .papers-list-element:hover {
  background-color: #f7fafc;
  transform: translateY(-2px);
}
.papers-list .papers-list-element:hover .paper-title {
  color: #3182ce;
}
.papers-list .papers-list-element.busy {
  animation: fadeCycle 2s infinite;
  opacity: 0.6;
  pointer-events: none;
}
@keyframes fadeCycle {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.8;
  }
}
.papers-list .papers-list-element .paper-details-left {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
}
@media (max-width: 992px) {
  .papers-list .papers-list-element .paper-details-left {
    width: 100%;
    flex-direction: row;
    gap: 0;
    align-items: center;
    justify-content: center;
  }
}
.papers-list .papers-list-element .paper-details-left .label {
  font-size: 0.8rem;
  font-weight: 400;
  color: #1a202c;
  text-align: right;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
  margin-right: 0.5rem;
  color: #2d3748;
}
.papers-list .papers-list-element .paper-details-left strong {
  width: 2.8rem;
}
.papers-list .papers-list-element .paper-details-left .paper-score, .papers-list .papers-list-element .paper-details-left .paper-year, .papers-list .papers-list-element .paper-details-left .paper-citations {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
}
.papers-list .papers-list-element .paper-details-left .paper-score .label, .papers-list .papers-list-element .paper-details-left .paper-year .label, .papers-list .papers-list-element .paper-details-left .paper-citations .label {
  font-size: 0.8rem;
  font-weight: 400;
  color: #1a202c;
  text-align: right;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
  margin-right: 0.5rem;
  color: #2d3748;
}
.papers-list .papers-list-element .paper-details-left .paper-score strong, .papers-list .papers-list-element .paper-details-left .paper-year strong, .papers-list .papers-list-element .paper-details-left .paper-citations strong {
  --highlight-color: #e01b50;
  --default-score-color: #3182ce;
  color: var(--default-score-color);
  width: 2.8rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #1a202c;
  text-align: right;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
  margin-right: 0.4rem;
}
.papers-list .papers-list-element .paper-details-left .paper-score strong .faint, .papers-list .papers-list-element .paper-details-left .paper-year strong .faint, .papers-list .papers-list-element .paper-details-left .paper-citations strong .faint {
  font-size: 0.7rem;
  font-weight: 400;
  color: #2d3748;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
}
@media (max-width: 992px) {
  .papers-list .papers-list-element .paper-details-left {
    flex-wrap: wrap;
    gap: 0rem;
  }
}
.papers-list .papers-list-element .paper-details-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
  flex-grow: 1;
}
.papers-list .papers-list-element .paper-details-right .paper-title-level {
  margin-bottom: 0.3rem;
}
.papers-list .papers-list-element .paper-details-right .paper-title-level .paper-title {
  font-size: 0.9rem;
  font-weight: 500;
  color: black;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  line-height: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@media (max-width: 992px) {
  .papers-list .papers-list-element .paper-details-right .paper-title-level .paper-title {
    font-size: 0.9rem;
    -webkit-line-clamp: 4;
  }
}
.papers-list .papers-list-element .paper-details-right .paper-journal {
  font-style: italic;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-align: left;
  flex-grow: 1;
  padding: 0 0.5rem;
  font-size: 0.8rem;
  color: #2d3748;
}
@media (max-width: 992px) {
  .papers-list .papers-list-element .paper-details-right .paper-journal {
    font-size: 0.8rem;
  }
}
.papers-list .placeholder {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  color: transparent !important;
}
.papers-list .placeholder strong {
  color: transparent !important;
}
.papers-list .paper-details-right.placeholder {
  flex-grow: 1;
  width: 100%;
  color: transparent !important;
}
.papers-list .paper-details-left.placeholder {
  color: transparent !important;
  background-color: rgba(230, 230, 230, 0.8);
  border-radius: 0.5rem;
}
.papers-list .paper-details-left.placeholder .label {
  color: transparent !important;
}
.papers-list .paper-authors.placeholder {
  width: 100%;
  background: #e6e6e6;
  height: 1.2rem;
  border-radius: 5px;
  border: none;
  color: transparent !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-top: 0.25rem;
  margin-left: 1rem;
}
.papers-list .paper-title-level.placeholder {
  width: 90%;
  background: #cccccc;
  height: 1.2rem;
  border-radius: 5px;
  border: none;
  color: transparent !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-bottom: 0.25rem;
}
.papers-list .paper-title-level.placeholder .paper-title {
  color: transparent !important;
}
.papers-list .paper-journal.placeholder {
  width: 20%;
  background: rgba(230, 230, 230, 0.7);
  height: 1.2rem;
  border-radius: 5px;
  border: none;
  color: transparent !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-left: 0rem;
  width: 80%;
  flex-grow: 1;
}

.papers-list-element.placeholder {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.papers-list-element.placeholder:hover {
  background-color: white !important;
}

.papers-list-element.selected {
  background-color: rgba(202, 217, 225, 0.4);
}

/* ------------------------------ single paper ------------------------------ */
.pd-no-paper {
  font-size: 2rem;
  font-weight: 700;
  color: #1a202c;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  line-height: 1.4rem;
}

.paper-details {
  padding: 1rem 2rem;
  height: calc(65vh + 2.5rem);
}
@media (max-width: 992px) {
  .paper-details {
    margin-top: 0rem;
    padding: 1rem;
  }
}
.paper-details.hide-controls {
  height: 65vh !important;
}

.pd-title {
  font-size: 1.2rem;
  font-weight: 700;
  color: #1a202c;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  line-height: 1.4rem;
  margin-bottom: 1rem;
}
.pd-title a {
  color: #1a202c;
}
.pd-title a:hover {
  color: #3182ce;
}

.pd-level {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: space-between;
  gap: 0.5rem;
}
@media (max-width: 992px) {
  .pd-level {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .pd-level .pd-journal {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 992px) {
  .pd-level-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
  }
}

.pd-authors {
  font-size: 0.9rem;
  font-weight: 400;
  color: #2d3748;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  line-height: 1.2rem;
  margin-bottom: 0.5rem;
}

.level {
  margin-bottom: 0;
  gap: 2rem;
}

.pd-year, .pd-citations, .pd-journal, .pd-doi {
  font-size: 0.9rem;
  color: #2d3748;
  margin-bottom: 0rem;
}
.pd-year strong, .pd-citations strong, .pd-journal strong, .pd-doi strong {
  margin-right: 0.2rem;
}
@media (max-width: 992px) {
  .pd-year, .pd-citations, .pd-journal, .pd-doi {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}

.pd-journal {
  max-width: 80%;
  font-style: italic;
}

.pd-citations {
  flex-grow: 1;
}

.pd-section {
  margin-top: 1.5rem;
}
.pd-section .pd-section-title {
  font-size: 0.9rem;
  font-weight: 600;
  color: #3182ce;
}
.pd-section .pd-section-text {
  padding: 0 0.5rem;
  text-justify: inter-word;
  font-size: 0.9rem;
  font-weight: 400;
  color: #1a202c;
  text-align: justify;
  font-family: "Montserrat", sans-serif;
  line-height: 1.2rem;
}
@media (max-width: 992px) {
  .pd-section .pd-section-text {
    padding: 0 0rem;
  }
}

.pd-title.placeholder {
  width: 100%;
  background: #cccccc;
  height: 2rem;
  border-radius: 5px;
  border: none;
  color: transparent !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.pd-authors.placeholder {
  width: 100%;
  background: #e6e6e6;
  height: 1.2rem;
  border-radius: 5px;
  border: none;
  color: transparent !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.pd-year.placeholder, .pd-citations.placeholder {
  width: 100%;
  background: #f2f2f2;
  height: 1.2rem;
  border-radius: 5px;
  border: none;
  color: transparent !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-bottom: 0.25rem;
  color: transparent !important;
}
.pd-year.placeholder strong, .pd-citations.placeholder strong {
  color: transparent !important;
}

.pd-journal.placeholder {
  width: 100%;
  background: #f2f2f2;
  height: 1.2rem;
  border-radius: 5px;
  border: none;
  color: transparent !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-bottom: 0.25rem;
  color: transparent !important;
}

.pd-section-title.placeholder {
  width: 20%;
  background: rgba(202, 217, 225, 0.8);
  height: 1.2rem;
  border-radius: 5px;
  border: none;
  color: transparent !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-bottom: 0.25rem;
  color: transparent !important;
}

.pd-section-text.placeholder {
  width: 100%;
  background: #f2f2f2;
  height: 1.2rem;
  border-radius: 5px;
  border: none;
  color: transparent !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin-bottom: 0.25rem;
  color: transparent !important;
  height: 4rem;
}

/* -------------------------------------------------------------------------- */
/*                                 SMART SORT                                 */
/* -------------------------------------------------------------------------- */
.sort-controls-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.5rem;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
  transition: background-color 0.3s ease;
  background-color: #f2f2f2;
  border: 1px solid #cccccc;
  border-bottom: none;
}

.sort-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.2rem;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
@media (max-width: 576px) {
  .sort-buttons-container {
    margin: 0.5rem 0;
    flex-direction: column;
    gap: 0.5rem;
    height: auto;
  }
}

.smart-sort-button {
  font-size: 0.8rem !important;
  padding: 0.25rem 0.5rem !important;
  width: fit-content !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  color: #e01b50;
  border: 1px solid #e01b50;
  border-radius: 2.5rem !important;
}
.smart-sort-button:hover {
  box-shadow: 0 0 0.5rem rgba(224, 27, 80, 0.2);
}
.smart-sort-button .smart-sort-chevron {
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.smart-sort-textarea-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
}
.smart-sort-textarea-container textarea {
  width: 95%;
  height: 6rem;
  border-radius: 0.5rem 0 0 0.5rem;
  border: none;
  padding: 0.5rem 1rem;
  border: 1px solid #b3beca;
  resize: none;
  border-right: none;
  outline: none;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: white;
  color: #2d3748;
}
.smart-sort-textarea-container textarea:focus {
  border-color: #e01b50;
}
.smart-sort-textarea-container button {
  height: 6rem;
  width: 5%;
  border-radius: 0 0.5rem 0.5rem 0;
  border: 1px solid #e01b50;
  background-color: #e01b50;
  color: white;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.smart-sort-textarea-container button:focus {
  border-color: #e01b50;
}
.smart-sort-textarea-container button.disabled {
  background-color: #f2f2f2;
  border: 1px solid #cccccc;
  color: gray;
  cursor: default;
}
@media (max-width: 576px) {
  .smart-sort-textarea-container textarea {
    width: 90%;
  }
  .smart-sort-textarea-container button {
    width: 10%;
  }
}

.sort-buttons {
  display: flex;
}
.sort-buttons button {
  padding: 0.15rem 0.5rem;
  font-size: 0.9rem;
  background-color: white;
  border: 1px solid #cccccc;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  color: #2d3748;
  background-color: #f7fafc;
}
.sort-buttons button.busy {
  opacity: 0.6;
  pointer-events: none;
  animation: pulse 1s infinite;
}
@media (max-width: 992px) {
  .sort-buttons button {
    font-size: 0.7rem;
  }
}
.sort-buttons button:hover {
  background-color: #f7fafc;
  color: #1a202c;
}
.sort-buttons button.active {
  background-color: #3182ce;
  color: white;
  border-color: #3182ce;
}
.sort-buttons button.disabled {
  background-color: #f7fafc;
  color: #cccccc;
  cursor: default;
  border-color: #b3b3b3;
}
.sort-buttons .left-button {
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: none !important;
}
.sort-buttons .middle-button {
  border-radius: 0;
  border-right: none !important;
}
.sort-buttons .right-button {
  border-radius: 0 0.5rem 0.5rem 0;
}

.papers-count {
  font-size: 0.9rem;
  color: #2d3748;
  text-align: right;
  padding-right: 1rem;
}
@media (max-width: 992px) {
  .papers-count {
    font-size: 0.8rem;
  }
}

.papers-list-element.placeholder {
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.papers-list-element.placeholder:hover {
  background-color: white !important;
}

.papers-list-element.selected {
  background-color: rgba(202, 217, 225, 0.4);
}

/* ---------------------------- Voting Buttons ---------------------------- */
.pd-vote-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0rem;
  margin-top: 0.2rem;
  padding-right: 0.5rem;
}

.pd-vote-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0;
  background: transparent;
  border: 1px solid #b3b3b3;
  color: #666666;
  padding: 0.2rem 0.6rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.pd-vote-button:hover {
  border-color: #666666;
}
.pd-vote-button.active {
  background-color: #666666;
  color: white;
  border-color: #666666;
}
.pd-vote-button svg {
  font-size: 0.9rem;
}
.pd-vote-button.left {
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: none !important;
}
.pd-vote-button.right {
  border-radius: 0 0.5rem 0.5rem 0;
}

.try-app {
  width: 100%;
  min-height: 100vh;
  overflow-y: auto;
  padding: 0rem 0 4rem 0;
}
@media (max-width: 992px) {
  .try-app {
    padding: 1rem 0 8rem 0;
  }
}
@media (max-width: 576px) {
  .try-app {
    padding: 2.5rem 0 6rem 0;
  }
}

.ta-search-description-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0rem;
  max-width: 1200px;
  margin: 0 auto;
  width: 80%;
  display: block;
  height: fit-content;
  padding: 0;
  margin-bottom: 1rem;
}
@media (max-width: 992px) {
  .ta-search-description-container {
    width: 90%;
  }
}
@media (max-width: 576px) {
  .ta-search-description-container {
    width: 95%;
  }
}

.try-app-search {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0rem;
}
@media (max-width: 992px) {
  .try-app-search {
    width: 90%;
    flex-direction: column;
    align-items: stretch;
    gap: 0;
    padding-top: 1rem;
  }
}
@media (max-width: 576px) {
  .try-app-search {
    width: 100%;
    padding: 0;
  }
}
.try-app-search .try-app-search-left {
  width: 80%;
  padding: 0rem;
}
.try-app-search .try-app-search-right {
  width: 100%;
}
@media (max-width: 992px) {
  .try-app-search .try-app-search-left,
.try-app-search .try-app-search-right {
    width: 100%;
    margin-left: 0;
    border-radius: 1rem;
    margin-bottom: 1rem;
  }
}

.try-app-search-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10rem;
  padding-left: 2rem;
  padding-bottom: 0.5rem !important;
}
.try-app-search-header .try-app-search-header-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  color: #cad3e0;
  padding-top: 0.5rem;
}
.try-app-search-header .try-app-search-header-icons i {
  cursor: pointer;
}
.try-app-search-header .try-app-search-header-icons i:hover {
  color: #203758;
}

.search-instructions {
  font-size: 0.8rem;
  font-weight: 400;
  color: #2d3748;
  text-align: left;
  font-family: "Fira Code", monospace;
  line-height: 1.2em;
  border-radius: 0.5rem;
  border: 1px solid #e6e6e6;
  background-color: rgba(255, 255, 255, 0.8);
}

.ta-start-button {
  width: 2rem !important;
  border-left: none !important;
  border-radius: 0 0.5rem 0.5rem 0 !important;
  padding: 0.5rem !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  overflow: hidden;
}
.ta-start-button i {
  margin: 0 !important;
  line-height: 1.5rem;
  height: 1.5rem;
  transition: margin 0.3s ease;
}
.ta-start-button svg {
  font-size: 1.2rem;
  margin: 0;
  transition: margin 0.3s ease;
}

.bib-upload-button {
  border-radius: 0.5rem 0 0 0.5rem !important;
}
@media (max-width: 992px) {
  .bib-upload-button {
    width: 70vw !important;
  }
}

.try-app-results {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .try-app-results {
    padding: 0 0.5rem;
    width: 95%;
  }
}
@media (max-width: 576px) {
  .try-app-results {
    padding: 0 0.25rem;
  }
}

.title-in-the-background {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-size: 2.2rem;
  font-weight: 700;
  color: #aab8ce;
  padding-left: 2rem;
  opacity: 0.6;
}
.title-in-the-background strong {
  color: #516a8d;
}
@media (max-width: 576px) {
  .title-in-the-background {
    text-align: center;
    padding: 0;
    font-size: 1.5rem;
  }
}

/* -------------------------------------------------------------------------- */
/*                                 OLD NAVBAR                                 */
/* -------------------------------------------------------------------------- */
.refy_navbar_container {
  width: 100%;
  padding: 1rem 0 1.5rem 0;
}
.refy_navbar_container .cl-userButtonTriggerm .cl-button, .refy_navbar_container .cl-open, .refy_navbar_container .cl-internal-c4jh7f {
  border: none !important;
  width: 3rem;
}

.refy_navbar {
  height: 3rem;
  width: 80%;
  margin: 0 auto;
  display: flex;
  position: static !important;
}
@media (max-width: 576px) {
  .refy_navbar {
    flex-direction: column !important;
    border-bottom: 2px solid #b9d0de;
  }
  .refy_navbar .navbar_links {
    margin: 0 !important;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
  }
}
.refy_navbar .navbar_logo {
  width: 1.5rem;
  height: auto;
  opacity: 0.8;
  margin-right: 0.5rem;
}
.refy_navbar .navbar_brand {
  font-size: 2.2em !important;
  color: white;
  font-weight: bold;
  text-decoration: none;
  line-height: 3rem;
  color: #3d73ac !important;
  font-family: "DM Serif Display", serif;
  font-size: 3em;
}
.refy_navbar .navbar_links {
  margin-left: auto;
  margin-right: 1rem;
  line-height: 3rem;
  color: rgba(49, 130, 206, 0.5) !important;
  display: flex !important;
}
.refy_navbar .navbar_links .navbar_link {
  color: white;
  text-decoration: none;
  margin: 0 1rem;
  color: #666666 !important;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
}
.refy_navbar .navbar_links .navbar_link:hover {
  color: #3182ce !important;
}
.refy_navbar .navbar_links .nav-user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 0rem;
  font-size: 0.8rem;
  font-weight: 400;
  color: #2d3748;
  text-align: right;
  font-family: "Fira Code", monospace;
  line-height: 1.5em;
  margin-right: 0 !important;
  margin-left: 2rem !important;
}
.refy_navbar .navbar_links .nav-user-info:hover {
  color: #2d3748 !important;
  cursor: default !important;
}
@media (max-width: 576px) {
  .refy_navbar .navbar_links .nav-user-info {
    display: none !important;
  }
}
.refy_navbar .navbar_links .user-button-container {
  position: relative;
  display: flex;
}
@media (max-width: 576px) {
  .refy_navbar .navbar_links .user-button-container {
    padding-top: 0.5rem;
  }
}
.refy_navbar .navbar_links .search-count-bubble {
  --size: 25px;
  display: none;
  position: absolute;
  top: calc(0.5rem - 8px );
  right: -8px;
  background-color: white;
  color: #3182ce;
  border: 1px solid #3182ce;
  border-radius: 50%;
  width: var(--size);
  height: var(--size);
  font-size: 0.6em;
  line-height: var(--size);
  text-align: center;
  font-weight: bold;
}
@media (max-width: 576px) {
  .refy_navbar .navbar_links .search-count-bubble {
    display: block !important;
  }
}

/* -------------------------------------------------------------------------- */
/*                               demo completed                               */
/* -------------------------------------------------------------------------- */
.demo-completed-title {
  width: fit-content;
  margin: 0rem auto 3rem auto;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: #3d73ac;
  text-align: center;
  font-family: "DM Serif Display", serif;
  line-height: 1.5em;
  font-size: 3.5rem;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  position: relative;
  padding: 0.5rem 1rem 0 1rem;
  border-bottom: 2px solid rgba(49, 130, 206, 0.5);
}
@media (max-width: 576px) {
  .demo-completed-title {
    font-size: 2.5rem;
    padding: 0.3rem 0.5rem;
  }
}

.demo-over {
  width: 90%;
  max-width: 1200px;
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  gap: 2rem;
  padding: 0;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
@media (max-width: 992px) {
  .demo-over {
    flex-direction: column;
    width: 95%;
  }
}
.demo-over .demo-over-content {
  width: 48%;
  height: 100%;
  margin: 0;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  text-align: left;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem rgba(61, 115, 172, 0.25);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
@media (max-width: 992px) {
  .demo-over .demo-over-content {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
}
.demo-over h1 {
  font-size: 1.5rem;
  font-weight: 700;
  color: #3d73ac;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
  margin-bottom: 0.25rem;
}
.demo-over h2 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #447fb7;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
}
.demo-over h3 {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgba(81, 127, 170, 0.6);
  text-align: left;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
}
.demo-over p {
  font-size: 0.9rem;
  font-weight: 400;
  color: #2d3748;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
}
.demo-over .highlight {
  background: linear-gradient(to bottom, transparent 50%, rgba(159, 207, 237, 0.3) 50%);
  padding: 0 0.2rem;
}
.demo-over .offer {
  background-color: rgba(202, 217, 225, 0.2);
  border-left: 4px solid #3182ce;
  padding: 1rem;
  border-radius: 0 0.5rem 0.5rem 0;
  font-size: 0.9rem;
  font-weight: 500;
  color: #3d73ac;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
}
.demo-over .payment-container {
  width: 100%;
  margin-top: 1rem;
}

/* -------------------------------------------------------------------------- */
/*                               STATUS DISPLAY                               */
/* -------------------------------------------------------------------------- */
.status-display {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0rem;
  flex-direction: column-reverse;
  max-height: 100px;
  overflow-y: auto;
  flex-direction: column-reverse;
  padding: 1.5rem 1rem 0.5rem 0rem;
}
@media (max-width: 576px) {
  .status-display {
    align-items: center;
    justify-content: center;
    padding: 0;
  }
}
.status-display .status-message {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  font-weight: 400;
  color: rgba(61, 115, 172, 0.8);
  text-align: left;
  font-family: "Fira Code", monospace;
  line-height: 1.5em;
}
.status-display .status-message.subitem {
  margin-left: 2rem;
}

.payment-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0rem 2rem;
  font-family: "Montserrat", sans-serif;
  width: 100%;
}
.payment-container h2 {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #2d3748;
}
.payment-container .payment-form {
  max-width: 400px;
  width: 100%;
  padding: 1rem 2rem;
}
.payment-container .payment-form h3 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  color: #447fb7;
}
.payment-container .form-group {
  margin-bottom: 0.5rem;
  font-size: 1em;
}
.payment-container .form-group label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #2d3748;
  margin-bottom: 0.5rem;
}
.payment-container .card-element-container {
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
  padding: 0.5rem;
  background-color: #f7fafc;
}
.payment-container button {
  width: 100%;
  padding: 0.5rem 1rem;
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.payment-container button:hover {
  background-color: #29323d;
}
.payment-container button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.payment-container button.processing {
  opacity: 0.75;
}
.payment-container .payment-status {
  margin-top: 0.5rem;
  text-align: center;
  font-weight: 700;
  border-radius: 0.25rem;
}
.payment-container .payment-status.success {
  color: #059669;
  background-color: rgba(5, 150, 105, 0.1);
}
.payment-container .payment-status.error {
  color: #f04d17;
  background-color: rgba(240, 77, 23, 0.1);
}
@media (max-width: 576px) {
  .payment-container {
    padding: 0;
  }
  .payment-container .payment-form {
    padding: 0 !important;
  }
}

@media (max-width: 576px) {
  .demo-completed-title {
    font-size: 1.75rem !important;
    margin-bottom: 1rem !important;
  }
}

.search-params {
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin-top: 0.5rem;
}
@media (max-width: 992px) {
  .search-params {
    width: 95%;
    flex-direction: column-reverse;
    align-items: center;
    gap: 0;
    margin-bottom: 1rem;
  }
}
.search-params .search-params-content {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.search-params .search-params-dates, .search-params .search-params-limit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0rem;
}
.search-params .search-params-date-range {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0rem;
}
.search-params .search-params-sep {
  color: #7b98b4;
}
.search-params .search-params-title, .search-params .search-params-hidden {
  font-size: 0.8rem;
  font-weight: 400;
  color: #7b98b4;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  line-height: 1rem;
  padding: 0.1rem;
  text-align: right;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
@media (max-width: 992px) {
  .search-params .search-params-title, .search-params .search-params-hidden {
    font-size: 0.8rem;
    padding: 0.1rem 0;
  }
}
.search-params .search-params-hidden {
  cursor: pointer;
  border: 1px solid transparent;
  padding: 0.2rem 0.5rem;
}
.search-params .search-params-hidden:hover {
  color: #3d73ac;
  border-color: #7b98b4;
  background-color: rgba(49, 130, 206, 0.05);
}
.search-params .search-params-hidden.close {
  border-color: #7b98b4;
}
.search-params input[type=number] {
  width: 4.5rem;
  padding: 0.05rem 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #e6e6e6;
  background-color: white;
  outline: none;
  font-size: 0.8rem;
  font-weight: 400;
  color: gray;
  text-align: center;
  font-family: "Fira Code", monospace;
  line-height: 1.5em;
  -moz-appearance: textfield;
  font-size: 0.8rem;
}
.search-params .input-year-start {
  border-radius: 0.25rem 0 0 0.25rem !important;
  border-right: none !important;
}
.search-params .input-year-end {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}

.survey, .popup-survey {
  width: 100%;
}
.survey h2, .popup-survey h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #3182ce;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
  margin-bottom: 1rem;
}
.survey textarea, .popup-survey textarea {
  resize: none;
  border: 1px solid #cccccc;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}
.survey .rating-container, .popup-survey .rating-container {
  margin-bottom: 1rem;
}
.survey .rating-container label, .popup-survey .rating-container label {
  display: block;
  margin-bottom: 0.5rem;
}
.survey .rating-buttons, .popup-survey .rating-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: space-between;
  gap: 0rem;
  width: 80%;
  margin-bottom: 0.5rem;
  margin: 1rem auto 0 auto;
}
.survey .rating-button, .popup-survey .rating-button {
  padding: 0.5rem;
  border: 1px solid #3182ce;
  color: #3182ce;
  background-color: white;
  cursor: pointer;
  transition: all 0.2s ease;
  border-left: none;
  font-size: 1.2rem;
}
.survey .rating-button:first-child, .popup-survey .rating-button:first-child {
  border-radius: 0.5rem 0 0 0.5rem;
  border-left: 1px solid #3182ce;
}
.survey .rating-button:last-child, .popup-survey .rating-button:last-child {
  border-radius: 0 0.5rem 0.5rem 0;
}
.survey .rating-button:hover, .popup-survey .rating-button:hover {
  background-color: #cad9e1;
}
.survey .rating-button.selected, .popup-survey .rating-button.selected {
  background-color: #3182ce;
  color: white;
}
.survey .rating-labels, .popup-survey .rating-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 0.25rem;
  width: 60%;
  margin: 0 auto;
  color: #999999 !important;
}
.survey .survey-buttons, .popup-survey .survey-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
.survey .thank-you-message, .popup-survey .thank-you-message {
  font-size: 1.2rem;
  font-weight: 600;
  color: #3182ce;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
  margin-top: 1rem;
}

.popup-survey {
  position: fixed;
  bottom: 2%;
  right: 2%;
  width: 40vw;
  max-width: 1200px;
  background-color: white;
  padding: 2rem;
  border-radius: 1rem;
  border: 1px solid #3182ce;
  box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.25);
}
.popup-survey .survey-buttons {
  width: 80%;
  margin: 0 auto;
  justify-content: space-between;
}

.onboarding-page {
  padding-bottom: 4rem;
  background-blend-mode: overlay;
  position: relative;
  background: linear-gradient(to bottom, #f7fafc, #e8f1f6) !important;
  background-attachment: fixed;
}
.onboarding-page::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("../../public/topography.svg");
  opacity: 0.02;
  pointer-events: none;
}
.onboarding-page .onboarding-container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(61, 115, 172, 0.25) !important;
  border: 1px solid #b9d0de !important;
  animation: fadeIn 0.5s ease-out;
  position: relative;
  z-index: 1;
}
@media (max-width: 576px) {
  .onboarding-page .onboarding-container {
    max-width: 90%;
    margin: 3rem auto;
  }
}
.onboarding-page .onboarding-title {
  font-size: 2rem;
  font-weight: 100;
  color: rgba(224, 27, 80, 0.7);
  text-align: center;
  font-family: "DM Serif Display", serif;
  line-height: 1.8rem;
  margin-bottom: 1.5rem;
}
.onboarding-page .onboarding-title b {
  font-weight: 400;
}
.onboarding-page .onboarding-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}
.onboarding-page .form-group {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  width: 100%;
}
.onboarding-page .form-group .form-group-icon {
  opacity: 0.5;
}
.onboarding-page .form-group label {
  font-size: 1.2rem;
  font-weight: 400;
  color: #447fb7;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.onboarding-page .form-group label .svg-inline--fa {
  font-size: 1.2em;
}
.onboarding-page .form-group input[type=text],
.onboarding-page .form-group select,
.onboarding-page .form-group textarea {
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #b9d0de;
  border-radius: 0.5rem;
  font-size: 1rem;
  color: #2d3748;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background: white;
}
.onboarding-page .form-group input[type=text]:focus,
.onboarding-page .form-group select:focus,
.onboarding-page .form-group textarea:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 2px rgba(49, 130, 206, 0.8);
}
.onboarding-page .form-group input[type=text]::placeholder,
.onboarding-page .form-group select::placeholder,
.onboarding-page .form-group textarea::placeholder {
  color: #b3b3b3 !important;
}
.onboarding-page .form-group input[type=text]::-webkit-input-placeholder,
.onboarding-page .form-group select::-webkit-input-placeholder,
.onboarding-page .form-group textarea::-webkit-input-placeholder {
  color: #b3b3b3 !important;
}
.onboarding-page .form-group input[type=text]::-moz-placeholder,
.onboarding-page .form-group select::-moz-placeholder,
.onboarding-page .form-group textarea::-moz-placeholder {
  color: #b3b3b3 !important;
}
.onboarding-page .form-group input[type=text]::-ms-input-placeholder,
.onboarding-page .form-group select::-ms-input-placeholder,
.onboarding-page .form-group textarea::-ms-input-placeholder {
  color: #b3b3b3 !important;
}
.onboarding-page select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='%23666' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 1.2em auto;
  padding-right: 2.5em;
  cursor: pointer;
}
.onboarding-page select::-ms-expand {
  display: none;
}
.onboarding-page select option {
  background-color: #ffffff;
  color: #2d3748;
}
.onboarding-page select .select-wrapper {
  width: 100%;
}
.onboarding-page select .select-wrapper select {
  width: 100%;
}
.onboarding-page select .select-wrapper::after {
  content: "▼";
  position: absolute;
  top: 50%;
  right: 1em;
  transform: translateY(-50%);
  pointer-events: none;
  font-size: 0.8em;
  color: #447fb7;
}
.onboarding-page select:focus + .select-menu {
  display: block;
}
.onboarding-page .select-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #ffffff;
  border: 1px solid #b9d0de;
  border-top: none;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
}
.onboarding-page .select-menu option {
  padding: 0.5rem 0.75rem;
  cursor: pointer;
}
.onboarding-page .select-menu option:hover {
  background-color: #cad9e1;
}
.onboarding-page textarea {
  resize: none;
  min-height: 100px;
}
.onboarding-page .position-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.5rem;
}
@media (max-width: 576px) {
  .onboarding-page .position-field {
    flex-direction: column;
    gap: 1rem;
  }
}
.onboarding-page .error {
  color: rgba(240, 77, 23, 0.8);
  padding-left: 0.5rem;
  font-size: 0.9rem;
  margin-top: 0.15rem;
}
.onboarding-page .submit-button {
  margin: 1rem auto 0;
  width: 80% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
.onboarding-page .submit-button .svg-inline--fa {
  font-size: 1.2em;
  margin-left: 0.5rem;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.onboarding-page .animate-in {
  animation: fadeIn 0.5s ease-out;
}
.onboarding-page .animate-field {
  animation: fieldAppear 0.3s ease-out;
}
@keyframes fieldAppear {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.highlight {
  background: rgba(109, 174, 227, 0.3);
  padding: 0.1rem 0.5rem !important;
  border-radius: 0.2rem;
  font-weight: 800 !important;
}

.demo-landing .navbar_brand {
  font-size: 3rem !important;
}
.demo-landing .navbar_logo {
  width: 3rem !important;
}
@media (max-width: 576px) {
  .demo-landing .refy_navbar_container .refy_navbar {
    border: none !important;
  }
}

.ta-login-manager {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: flex-start;
  gap: 5rem;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
}
@media (max-width: 576px) {
  .ta-login-manager {
    flex-direction: column !important;
    gap: 2rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
.ta-login-manager .ta-login-manager-description {
  width: 50%;
  padding-top: 8rem !important;
}
@media (max-width: 576px) {
  .ta-login-manager .ta-login-manager-description {
    padding-top: 0rem !important;
    width: 100%;
  }
}
.ta-login-manager .ta-login-manager-description .ta-login-manager-description-inner {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0rem;
}
.ta-login-manager .ta-login-manager-description .ta-login-title {
  font-size: 2.1em;
  font-weight: 700;
  color: #e01b50;
  text-align: center;
  font-family: "DM Serif Display", serif;
  line-height: 1.5em;
}
.ta-login-manager .ta-login-manager-description .ta-login-subtitle {
  font-size: 1.2em;
  font-weight: 400;
  color: #447fb7;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
  margin-bottom: 1.5rem !important;
}
.ta-login-manager .ta-login-manager-description .ta-login-features {
  list-style-type: none;
  padding-left: 0;
  width: 90%;
  margin-left: 3rem !important;
}
@media (max-width: 576px) {
  .ta-login-manager .ta-login-manager-description .ta-login-features {
    margin-left: 0 !important;
    width: 100%;
    text-align: center;
  }
}
.ta-login-manager .ta-login-manager-description .ta-login-features li {
  font-size: 1em;
  font-weight: 400;
  color: #2d3748;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
  margin-bottom: 0.5rem;
  font-family: "Montserrat", sans-serif !important;
}
.ta-login-manager .ta-login-manager-description .ta-login-features li:before {
  content: "•";
  color: #3182ce;
  font-weight: bold;
  font-size: 1.2em;
  line-height: 1.2em;
  display: inline-block;
  width: 1em;
}
@media (max-width: 576px) {
  .ta-login-manager .ta-login-manager-description .ta-login-features li {
    text-align: center;
  }
  .ta-login-manager .ta-login-manager-description .ta-login-features li:before {
    display: none;
  }
}
.ta-login-manager .ta-login-manager-description .ta-login-new-info {
  font-size: 0.9em;
  font-weight: 400;
  color: #447fb7;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
}
.ta-login-manager .ta-login-manager-description .ta-login-cta {
  margin: 1rem auto !important;
  font-size: 1.2em !important;
  width: fit-content !important;
  margin-top: 1rem;
}
.ta-login-manager .ta-login-manager-description .ta-login-trial {
  font-size: 0.8em;
  font-weight: 400;
  color: #447fb7;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
  margin-top: 0.5rem;
  width: 80%;
  margin: 0 auto;
}
.ta-login-manager .ta-login-manager-illustration {
  perspective: 1000px;
  width: 50%;
  height: 90%;
}
.ta-login-manager .ta-login-manager-illustration .paper-flip-container {
  width: 80%;
  margin: 0 auto;
  height: 80vh;
  position: relative;
  transform-style: preserve-3d;
}
.ta-login-manager .ta-login-manager-illustration .paper-flip-front,
.ta-login-manager .ta-login-manager-illustration .paper-flip-back {
  position: absolute;
  width: 100%;
  height: 80vh !important;
  max-height: 600px;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 1rem rgba(61, 115, 172, 0.25);
  border: 1px solid #cccccc;
  z-index: 1;
  position: relative;
  display: block;
}
.ta-login-manager .ta-login-manager-illustration .paper-flip-title {
  font-size: 1.8em;
  font-weight: 700;
  color: #3182ce;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
  position: absolute;
  top: 1.5rem;
  padding: 0 1.5rem;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2px);
}
.ta-login-manager .ta-login-manager-illustration .paper-flip-title strong {
  color: #e01b50;
  font-weight: 800;
  font-style: underline;
}
.ta-login-manager .cl-rootBox {
  margin: 0 auto !important;
}
.ta-login-manager .cl-formButtonPrimary {
  background: #1971dc !important;
  border: none !important;
  box-shadow: none !important;
}

.ta-login-cta {
  margin-top: 1rem;
}

@media (max-width: 576px) {
  .ta-login-manager .ta-login-manager-illustration {
    width: 100%;
    height: auto;
    margin-bottom: 2rem;
  }
}
.ta-login-manager .ta-login-manager-illustration .mobile-cards-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 60vh !important;
}
.ta-login-manager .ta-login-manager-illustration .mobile-card {
  width: 100%;
  height: 60vh !important;
  transform: none !important;
}
@media (max-width: 576px) {
  .ta-login-manager .ta-login-manager-illustration .paper-flip-container {
    width: 100%;
    height: 60vh !important;
    margin-bottom: 1rem;
  }
}
@media (max-width: 576px) {
  .ta-login-manager .ta-login-manager-illustration .paper-flip-front,
.ta-login-manager .ta-login-manager-illustration .paper-flip-back {
    height: 60vh !important;
  }
}
@media (max-width: 576px) {
  .ta-login-manager .ta-login-manager-illustration .paper-flip-title {
    font-size: 1.4em;
    padding: 0 1rem;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes spin {
  15% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(180deg);
  }
  70% {
    transform: rotate(180deg);
  }
  85% {
    transform: rotate(0deg);
  }
}
@keyframes borderAlpha {
  0% {
    border-color: rgba(49, 130, 206, 0);
  }
  50% {
    border-color: #3182ce;
  }
  100% {
    border-color: rgba(49, 130, 206, 0);
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
.app-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;
  height: 100vh;
  width: 100vw;
}

.views-container {
  height: 100vh;
  overflow-y: auto;
  transition: margin-left 0.3s ease;
  width: calc(100vw - 200px);
  margin-left: 200px;
}
@media (max-width: 768px) {
  .views-container {
    width: 100vw;
    margin-left: 0;
  }
}

body {
  background: linear-gradient(to bottom, #f7fafc, #d9e7f1) !important;
  min-height: 100vh;
  background-attachment: fixed;
}

/* -------------------------------------------------------------------------- */
/*                                   NAVBAR                                   */
/* -------------------------------------------------------------------------- */
.nav-bar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0;
  background-color: #3c5676;
  color: white;
  width: 200px;
  height: 100vh;
  padding: 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
}
@media (max-width: 768px) {
  .nav-bar {
    width: 100vw;
    transform: translateX(-100%);
    padding-top: 50px;
  }
  .nav-bar.open {
    transform: translateX(0);
  }
  .nav-bar.open .nav-toggle {
    width: 100%;
    background-color: #3182ce;
  }
}

.nav-toggle {
  display: none;
  position: fixed;
  z-index: 1001;
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 0 0 1rem 0;
  font-size: 1.5rem;
  padding: 0.7rem;
  cursor: pointer;
}
@media (max-width: 768px) {
  .nav-toggle {
    display: block;
  }
}
.nav-toggle .nav-toggle-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0;
  gap: 0.5rem;
}

.nav_user, .nav_settings {
  padding: 1rem 2rem;
  width: 100%;
}

.nav_menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0.5rem;
  flex-grow: 1;
  width: 100%;
  padding: 2rem 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.nav_menu_item {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}
.nav_menu_item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
.nav_menu_item.active {
  background-color: #3182ce;
  color: white;
}

.nav_user {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.2rem;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.nav_user_name {
  font-size: 1.5rem;
  font-weight: 600;
  color: white;
  text-align: left;
  font-family: "DM Serif Display", serif;
  line-height: 1.5em;
}

.nav_user_subscription {
  font-size: 0.9rem;
  opacity: 0.7;
}

.nav_settings {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 0.2rem;
}

.nav_settings_item {
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.3s ease;
}
.nav_settings_item:hover {
  opacity: 1;
}

.modal_overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(230, 230, 230, 0.7);
  z-index: 100;
  backdrop-filter: blur(5px);
  top: 0;
  left: 0;
}
@media (max-width: 992px) {
  .modal_overlay {
    width: 100vw;
    margin-left: 0;
  }
}
.modal_overlay .modal_box {
  height: fit-content;
  width: 80%;
  max-height: 90vh;
  max-width: 1200px;
  overflow-y: auto;
  margin: 2rem auto;
  border: 1px solid #cccccc;
  padding: 2rem 4rem;
  border-radius: 0.5rem;
  background: white;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}
@media (max-width: 992px) {
  .modal_overlay .modal_box {
    width: 90%;
    padding: 4rem 2rem;
  }
}
.modal_overlay .modal_title {
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700;
  color: #3182ce;
  text-align: left;
  font-family: "DM Serif Display", serif;
  line-height: 1.5em;
}
.modal_overlay .modal_content {
  font-size: 0.9em;
  color: #010101;
  margin-bottom: 1rem;
  text-align: justify;
}
.modal_overlay .modal_content p, .modal_overlay .modal_content ul {
  margin-bottom: 1.5rem !important;
}
.modal_overlay button {
  margin-top: 2rem;
}
.modal_overlay strong {
  color: #b9d0de;
  font-weight: 700;
}
.modal_overlay a {
  color: #1a202c;
  font-weight: 700;
}
.modal_overlay b {
  color: #3182ce;
}
.modal_overlay .modal_close_button {
  position: absolute;
  top: 0rem;
  right: 2rem;
  font-size: 1.1rem;
  width: fit-content;
  color: #3182ce;
  cursor: pointer;
  z-index: 101;
  padding: 0.5rem;
  line-height: 1;
}

input[type=file] {
  display: none;
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: #ffffff;
}

.refy_box {
  border: 1px solid #cccccc;
  border-radius: 1rem !important;
  padding: 0 2rem 1rem 2rem;
  position: relative;
  background: white;
}
.refy_box:hover {
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #cccccc;
  border-color: #447fb7;
}

.inline_image {
  height: 2rem;
  display: inline-block;
}

/* -------------------------------------------------------------------------- */
/*                                   BUTTONS                                  */
/* -------------------------------------------------------------------------- */
.button-primary {
  background-color: #1971dc;
  color: white;
  border: 1px solid #1971dc;
}
.button-primary:hover {
  box-shadow: 2px 2px 0.5rem rgba(25, 113, 220, 0.4);
}

.button-accent {
  background-color: white;
  color: #e01b50;
  border: 1px solid #e01b50;
}
.button-accent:hover {
  box-shadow: 2px 2px 0.5rem rgba(224, 27, 80, 0.4);
  background-color: #e01b50;
  color: white;
}

.button-secondary {
  background-color: white;
  color: #1971dc;
  border: 1px solid #1971dc;
}
.button-secondary:hover {
  box-shadow: 2px 2px 0.5rem rgba(49, 130, 206, 0.2);
}
.button-secondary:hover .button-text {
  text-decoration: underline;
}

.text-button {
  background-color: transparent;
  color: #1971dc;
  border: none;
  font-weight: 400 !important;
}
.text-button:hover {
  text-decoration: underline;
}

.refy_button {
  font-weight: 600;
  border-radius: 0.5rem;
  width: 12rem;
  text-align: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  transition: all 0.1s ease;
  overflow: hidden;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
}
.refy_button.disabled {
  background-color: #f2f2f2;
  border: 1px solid #cccccc;
  color: gray;
  cursor: default;
}
.refy_button.disabled:hover {
  box-shadow: none;
  scale: 1 !important;
  border: 1px solid #999999;
}
.refy_button.busy {
  background-color: #f2f2f2;
  border: 1px solid #cccccc;
  color: #2d3748;
  cursor: default;
}
.refy_button.busy:hover {
  box-shadow: none;
  scale: 1 !important;
  border: 1px solid #cccccc !important;
}
.refy_button label {
  margin-bottom: 0 !important;
}

.button-wide {
  width: 24rem;
}
@media (max-width: 992px) {
  .button-wide {
    margin: 0 auto;
  }
}

.button-hide {
  width: 0rem !important;
  padding: 0rem !important;
  overflow: hidden;
  border: none !important;
}

/* -------------------------------------------------------------------------- */
/*                               BIBTEXT LOADER                               */
/* -------------------------------------------------------------------------- */
.upload-buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0rem;
  width: 25rem;
  border-radius: 0.5rem;
  border: 2px solid transparent;
  position: relative;
  margin-left: 2rem;
}

.upload-buttons-container-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0rem;
  height: 2rem;
}

.loaded-file-name-container {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.2rem;
  padding: 0;
  height: fit-content !important;
  border-radius: 0.5rem;
  z-index: 1;
  font-size: 0.8rem;
}

.loaded-file-name-label {
  padding-top: 0.05rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #447fb7;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.loaded-file-name {
  font-size: 0.8rem;
  font-weight: 400;
  color: #7b98b4;
  text-align: left;
  font-family: "Fira Code", monospace;
  line-height: 1.5em;
  padding: 0;
  border: none;
  height: fit-content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.loaded-file-size {
  color: #7b98b4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.loaded-file-name-container.has-file {
  opacity: 1;
}

.upload-buttons.has-file {
  padding: 0.5rem 0 !important;
}
@media (max-width: 992px) {
  .upload-buttons.has-file {
    padding-bottom: 0 !important;
  }
}

