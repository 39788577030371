.onboarding-page
    padding-bottom: 4rem
    background-blend-mode: overlay
    position: relative
    background: linear-gradient(to bottom, $background-page, darken($background-page, 4%)) !important
    background-attachment: fixed

    &::before
        content: ''
        position: fixed
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-image: url('../../public/topography.svg')
        opacity: 0.02
        pointer-events: none

    .onboarding-container
        max-width: 600px
        margin: 2rem auto
        padding: 2rem 
        background-color: $background-color
        border-radius: $border-radius-large
        box-shadow: $box-shadow-blue-large !important
        border: 1px solid $secondary200 !important
        animation: fadeIn 0.5s ease-out
        position: relative
        z-index: 1

        @include respond-to(mobile)
            max-width: 90%
            margin: 3rem auto

    .onboarding-title
        @include text(largest, 100, rgba($accent, .7), center, $font-family-bold, 1.8rem)
        margin-bottom: 1.5rem

        b
            font-weight: 400


    .onboarding-form
        display: flex
        flex-direction: column
        gap: 1rem
        width: 100%

    .form-group
        display: flex
        flex-direction: column
        gap: 0.2rem
        width: 100%

        .form-group-icon
            opacity: 0.5

        label
            @include text(large, 400, $secondary600)
            padding-left: 0.5rem
            display: flex
            align-items: center
            gap: 0.5rem

            .svg-inline--fa
                font-size: 1.2em

        input[type="text"],
        select,
        textarea
            width: 100%
            padding: 0.5rem 0.75rem
            border: 1px solid $secondary200
            border-radius: $border-radius
            font-size: $font-size
            color: $text-color
            transition: $transition
            background: white

            &:focus
                outline: none
                border-color: transparent
                box-shadow: 0 0 0 2px rgba($secondary, .8)

            &::placeholder
                color: $grey700 !important
            &::-webkit-input-placeholder 
                color: $grey700 !important
            &::-moz-placeholder
                color: $grey700 !important
            &::-ms-input-placeholder
                color: $grey700 !important

    select
        appearance: none
        -webkit-appearance: none
        -moz-appearance: none
        background-image: url("data:image/svg+xml;utf8,<svg fill='%23666' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
        background-repeat: no-repeat
        background-position: right 0.7em top 50%
        background-size: 1.2em auto
        padding-right: 2.5em
        cursor: pointer

        &::-ms-expand
            display: none

        option
            background-color: $background-color
            color: $text-color

        // Custom dropdown styles
        .select-wrapper
            width: 100%

            select
                width: 100%

            &::after
                content: '\25BC'
                position: absolute
                top: 50%
                right: 1em
                transform: translateY(-50%)
                pointer-events: none
                font-size: 0.8em
                color: $secondary600

    // Dropdown menu styles
    select:focus
        & + .select-menu
            display: block

    .select-menu
        display: none
        position: absolute
        top: 100%
        left: 0
        right: 0
        z-index: 10
        background-color: $background-color
        border: 1px solid $secondary200
        border-top: none
        border-radius: 0 0 $border-radius $border-radius
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)
        max-height: 200px
        overflow-y: auto

        option
            padding: 0.5rem 0.75rem
            cursor: pointer

            &:hover
                background-color: $secondary100

    textarea
        resize: none
        min-height: 100px

    .position-field
        @include flex(row, space-between, flex-start, 0.5rem)

        @include respond-to(mobile)
            flex-direction: column
            gap: 1rem

    .error
        color: rgba($error, .8)
        padding-left: 0.5rem
        font-size: $font-size-small
        margin-top: 0.15rem



    .submit-button
        margin: 1rem auto 0
        width: 80% !important
        display: flex
        justify-content: center
        align-items: center
        gap: 0.5rem

        .svg-inline--fa
            font-size: 1.2em
            margin-left: 0.5rem

    @keyframes fadeIn
        from
            opacity: 0
            transform: translateY(20px)
        to
            opacity: 1
            transform: translateY(0)

    // Framer Motion animations
    .animate-in
        animation: fadeIn 0.5s ease-out

    .animate-field
        animation: fieldAppear 0.3s ease-out

    @keyframes fieldAppear
        from
            opacity: 0
            transform: translateX(-20px)
        to
            opacity: 1
            transform: translateX(0)