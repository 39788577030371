
@mixin fadeCycle($max-opacity: 1, $min-opacity: 0.2, $duration: 1s)
    @keyframes fadeCycle
        0%
            opacity: $max-opacity
        
        50%
            opacity: $min-opacity

        100%
            opacity: $max-opacity

    animation: fadeCycle $duration infinite


@mixin respond-to($breakpoint)
  @if $breakpoint == mobile
    @media (max-width: 576px)
      @content
  @else if $breakpoint == medium
    @media (max-width: 768px)
      @content
  @else if $breakpoint == tablet
    @media (max-width: 992px)
      @content
  @else if $breakpoint == desktop
    @media (max-width: 1200px)
      @content

  

@mixin text($font-size: normal, $font-weight: 400, $font-color: $text-color, $justify: left, $font: $font-family, $line-height: 1.5em)
  @if $font-size == smallest
    font-size: $font-size-smallest
  @else if $font-size == smaller
    font-size: $font-size-smaller
  @else if $font-size == small
    font-size: $font-size-small
  @else if $font-size == normal
    font-size: $font-size
  @else if $font-size == large
    font-size: $font-size-large
  @else if $font-size == larger
    font-size: $font-size-larger
  @else if $font-size == largest
    font-size: $font-size-largest
  @else
    font-size: $font-size
    
  font-weight: $font-weight
  color: $font-color
  text-align: $justify
  font-family: $font
  line-height: $line-height


@mixin flex($direction: row, $justify: flex-start, $align: stretch, $gap: 0)
  display: flex
  flex-direction: $direction
  justify-content: $justify
  align-items: $align
  gap: $gap

@mixin transition($property: all, $duration: 0.3s, $timing: ease)
  transition: $property $duration $timing

@mixin scrollbar-hidden
  scrollbar-width: none
  -ms-overflow-style: none
  &::-webkit-scrollbar
    display: none

@mixin placeholder-text($font-size, $font-color, $width)
  width: $width
  background: $font-color
  height: $font-size
  border-radius: 5px
  border: none
  color: transparent !important
  @include non-selectable-text()

@mixin non-selectable-text()
  user-select: none // Standard syntax
  -webkit-user-select: none // For Chrome, Safari, and Opera
  -moz-user-select: none // For Firefox
  -ms-user-select: none // For Internet Explorer/Edge

.non-selectable-text
  @include non-selectable-text()


@mixin level-flex($align-items: space-between, $justify-content: space-between, $gap: 1rem)
    display: flex
    flex-direction: row
    justify-content: $justify-content
    align-items: $align-items
    gap: $gap

